import styles from './styles.module.scss';
import { useLottiePlayer, useTranslation } from '@starsoft/common/hooks';
import { NoDataComponentProps } from './props';

export function NoDataComponent({
  isSecondaryStyles,
  isSmall,
  tableStyles,
}: NoDataComponentProps) {
  const { t } = useTranslation('common');

  const { lottieAnimationRef } = useLottiePlayer({
    path: '/empty-search.json',
    loop: true,
  });

  return (
    <div
      className={`${styles.container} ${isSecondaryStyles ? styles['container--secondary-styles'] : ''} ${isSmall ? styles['container--small'] : ''} ${tableStyles ? styles['container--table'] : ''}`}
    >
      <div className={styles.container__lottie} ref={lottieAnimationRef} />
      <div className={styles.container__wrapper}>
        <h6 className={styles.container__title}>{t('no_data_title')}</h6>
        <p className={styles.container__description}>{t('no_data_found')}</p>
      </div>
    </div>
  );
}
