import { getValue } from '../../helpers';

import styles from '../../styles.module.scss';
import { TableGridRowProps } from './props';
import { FieldValues } from 'react-hook-form';
import { memo, useCallback } from 'react';
import { TableGridColumn } from '../../props';
import { preventRedirectColumns } from '../prevent-items';

function TableGridRow<T extends FieldValues>({
  columns,
  row,
  index,
  isSecondaryStyles,
  useLargerRows,
  handleRedirect,
}: TableGridRowProps<T>) {
  const handleOnClick = useCallback(
    (row: T, column: TableGridColumn<T>) => {
      if (
        handleRedirect &&
        !preventRedirectColumns.some(v => column.field == v)
      ) {
        handleRedirect(row);
        return;
      }
    },
    [handleRedirect],
  );
  return (
    <tr
      className={`row ${styles['container__table-container__body__row']} ${index % 2 != 0 ? styles['container__table-container__body__row--odd'] : ''} ${row && isSecondaryStyles ? styles['container__table-container__body__row--secondary'] : ''} ${row && useLargerRows ? styles['container__table-container__body__row--larger'] : ''} ${handleRedirect ? styles['table-row--clickable'] : ''}`}
    >
      {columns.map((column, idx) => {
        if (column.renderCell) {
          return (
            <td
              onClick={() => handleOnClick(row, column)}
              className={`${styles['container__table-container__cell']} ${column?.alignEnd ? styles['container__table-container__cell--align-end'] : ''} ${column?.alignCenter ? styles['container__table-container__cell--align-center'] : ''}`}
              key={`table_cell_${getValue(row, column.field)}_${idx}`}
            >
              {column.renderCell(row, index)}
            </td>
          );
        }

        if (column.valueFormatter) {
          return (
            <td
              onClick={() => handleOnClick(row, column)}
              className={`${styles['container__table-container__cell']} ${column?.alignEnd ? styles['container__table-container__cell--align-end'] : ''} ${column?.alignCenter ? styles['container__table-container__cell--align-center'] : ''}`}
              key={`table_cell_${getValue(row, column.field)}_${idx}`}
            >
              {column.valueFormatter(row, index)}
            </td>
          );
        }

        return (
          <td
            onClick={() => handleOnClick(row, column)}
            className={`${styles['container__table-container__cell']} ${column?.alignEnd ? styles['container__table-container__cell--align-end'] : ''} ${column?.alignCenter ? styles['container__table-container__cell--align-center'] : ''}`}
            key={`table_cell_${getValue(row, column.field)}_${idx}`}
          >
            {getValue(row, column.field)}
          </td>
        );
      })}
    </tr>
  );
}

export default memo(TableGridRow) as typeof TableGridRow;
